@import '@asu/bootstrap4-theme/dist/css/bootstrap-asu.min.css';

body {
  margin: 0;
  min-height: 100vh;
}

#root {
  height: 100%;
  min-height: 100vh;
}
