/* Override the border and padding that are applied by the ASU themes styled checkbox lists. */
.form-check {
  border: 0;
  padding-left: 0;
}

textarea::placeholder,
input::placeholder {
  color: #747474 !important;
}

.form-control.is-invalid:not(:focus) {
  border-style: solid;
  border: 1px solid #b72a2a;
  border-bottom: 8px solid #b72a2a;
}

form.uds-form .form-check input[type='checkbox'] + label.form-check-label {
  font-weight: normal;
}

.is-invalid .invalid-feedback {
  display: block;
}

small.invalid-feedback {
  font-size: 90%;
}

/* Override the ASU theme form-group class' margin. Otherwise, the "Other" textboxes are not fully displayed. */
.list-group {
  margin-bottom: 2rem;
}

.required {
  font-size: 3em;
  line-height: 0.45em;
  color: #8c1d40;
  vertical-align: -50%;
}

/* Checkbox list styles. */
.campaign-list {
  list-style: none;
  padding: 0;
}

.campaign-list-item {
  display: flex;
  align-items: center;
}

.campaign-list-item label {
  font-weight: normal;
  margin-left: 0.2rem;
  vertical-align: middle;
}
/* End checkbox list styles. */
